<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input placeholder="请输入您要搜索的关键词" v-model="params.keyword" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.permission" placeholder="权限" filterable clearable>
						<el-option v-for="permission in permissions" :key="permission.id" :label="permission.name" :value="permission.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getRoles({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="onEdit({operate: 3})" :disabled="!$utils.create('roles')">添加角色</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper pa" height="100%" :data="roles" :size="theme.size">
					<el-table-column prop="name" label="基本信息" min-width="260">
						<template slot-scope="scope">
							<h3>{{scope.row.name}}<el-tag size="mini" type="warning" class="ml5">共{{scope.row.permissions.length}}个权限，授权{{scope.row.employees_count}}名员工</el-tag></h3>
							<p class="fs12 c9">{{scope.row.description}}</p>
						</template>
					</el-table-column>
					<el-table-column label="操作范围" width="80">
						<template slot-scope="scope">
							<p v-if="!admin_user.shop_id">{{ scope.row.shop ? scope.row.shop.name : theme.group }}</p>
							<p>{{operates[scope.row.operate]}}业务</p>
						</template>
					</el-table-column>
					<el-table-column label="能力权限" min-width="400">
						<template slot-scope="scope">
							<template v-for="(permission, p) in scope.row.permissions">
								<el-tooltip class="permission" effect="dark" placement="top-start" :key="permission.id" :content="permission.description" :disabled="!permission.description" v-if="p<30 || permission.id==params.permission">
									<el-tag size="mini" :type="permission.id==params.permission?'success':''">{{permission.name}}</el-tag>
								</el-tooltip>
							</template>
							<el-tag size="mini" type="danger" v-if="scope.row.id === 1 && scope.row.permissions.length != permissions.length">技能不全，建议更新</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
					</el-table-column>
					<el-table-column prop="name" label="更多操作" width="140">
						<template slot-scope="scope">
							<el-button type="text" @click="onEdit(scope.row)" :disabled="!$utils.update('roles') || scope.row.deleted_at">编辑</el-button>
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('roles')" v-if="scope.row.deleted_at">恢复</el-button>
							<el-button type="text" @click="onDelete(scope.row)" :disabled="scope.row.id === 1 || !$utils.delete('roles')" v-else>删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getRoles({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getRoles({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<el-drawer :title="role.id?'编辑角色':'创建角色'" :size="theme.width <= 550 ? '100%' : '550px'" :visible.sync="create_drawer" @closed="$refs.create_form.resetFields(); role = {};">
				<div class="pl10 pr10">
					<el-form ref="create_form" label-position="top" :model="role" :rules="role_rules" :size="theme.size" status-icon>
						<el-form-item label="名称" prop="name">
							<el-input autocomplete="off" placeholder="请输入角色名称" v-model="role.name">
								<el-select slot="prepend" style="width: 120px;" v-model="role.shop_id" placeholder="请输入关键词搜索" v-if="!admin_user.shop_id" :disabled="role.id === 1" filterable clearable>
									<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
								</el-select>
							</el-input>
						</el-form-item>
						<el-form-item label="描述" prop="description">
							<el-input autocomplete="off" placeholder="请输入角色描述" type="textarea" rows="2" v-model="role.description"></el-input>
						</el-form-item>
						<el-form-item label="操作" prop="operate">
							<el-radio-group v-model="role.operate">
								<el-radio v-for="(operate, o) in operates" :key="o" :label="+o">{{operate}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="权限" prop="groups">
							<template v-for="group in role.groups">
								<div v-if="group.permissions.length" :key="group.id">
									<el-divider content-position="left">
										<el-checkbox :indeterminate="group.indeterminate" v-model="group.checked" @change="(v) => { handleCheckAllChange(v, group.id) }">{{group.name}}</el-checkbox>
										<!-- <el-button type="text" icon="el-icon-setting" style="margin-left: 10px;" @click="$message.error('未启用')">设置</el-button> -->
									</el-divider>
									<el-checkbox-group v-model="group.permissioned" @change="handleCheckChange(group)">
										<el-checkbox v-for="permission in group.permissions" :key="permission.id" :label="permission.id">
											{{permission.name}}
											<el-tooltip effect="dark" v-if="permission.description" :content="permission.description">
												<i class="el-icon-question"></i>
											</el-tooltip>
										</el-checkbox>
									</el-checkbox-group>
								</div>
							</template>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-drawer>
		</template>
	</main-table-a>
</template>

<style>
	.permission {
		margin: 0 5px 5px 0;
	}
</style>
<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			handleCheckChange (a) {
				a.checked = a.permissions.length == a.permissioned.length;
				a.indeterminate = a.permissions.length == a.permissioned.length ? false : (a.permissioned.length ? true :false);
			},
			handleCheckAllChange (v, g) {
				this.role.groups.map((group) => {
					if (group.id === g) {
						const permissioned = v ? group.permissions.map((p) => p.id) : [];
						group.permissioned = permissioned;
						group.indeterminate = group.permissions.length == permissioned.length ? false : (permissioned.length ? true :false);
					} 
				});
			},
			onEdit (r) {
				const permissions = r.permissions ? r.permissions.map((p) => p.id) : [];
				const groups = this.groups.map((group) => {
					const permissioned = [];
					group.permissions.map((p) => {
						if (permissions.indexOf(p.id) >= 0) {
							permissioned.push(p.id);
						}
					});
					return {
						...group,
						permissioned,
						checked: group.permissions.length == permissioned.length,
						indeterminate: group.permissions.length == permissioned.length ? false : (permissioned.length ? true :false)
					};
				});
				this.role = {...r, permissions, groups };
				this.create_drawer = true;
			},
			onDelete (r) {
				const { id, name, employees_count, permissions } = r;
				if (id === 1) return this.$message.error('不能删除！【'+name+'】为系统创建角色。');
				if (employees_count) return this.$message.error('不能删除！【'+name+'】角色下还有 '+employees_count+' 名员工，请移除员工再执行。');
				if (permissions.length) return this.$message.error('不能删除！【'+name+'】角色下还有 '+permissions.length+' 个权限，请移除权限再执行。');
				this.$confirm('确定要删除【'+name+'】角色吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_ROLES, {params: {action: 'delete', id: id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message.success(msg);
					this.getRoles(this.params);
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】角色吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_ROLES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message.success(msg);
					this.getRoles(this.params);
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					const { groups } = this.role;
					var permissions = [];
					groups.map((g) => {
						permissions = [...permissions, ...g.permissioned];
					})
					if (!permissions.length) return this.$message.error("最少要选择1项权限！");
					this.submitRole({...this.role, permissions});
				});
			},
			async submitRole (data) {
				const res = await this.$http.post(this.$api.URI_ROLES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getRoles(this.params);
						}
					}
				});
			},
			async getRoles (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ROLES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.roles = result.data;
				this.shops = result.shops;
				this.operates = result.operates;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async getPermissions (params, loading= false) {
				const res = await this.$http.get(this.$api.URI_PERMISSIONS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.groups = result;
				var permissions = [];
				result.map((group) => {
					permissions = [...permissions, ...group.permissions];
				});
				this.groups = result;
				this.permissions = permissions;
			}
		},
		data () {
			return {
				role: {},
				roles: [],
				shops: [],
				groups: [],
				operates: [],
				permissions: [],
				create_drawer: false,
				params: {
					perPage: 10
				},
				role_rules: {
					name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
				}
			}
		},
		created () {
			this.getRoles(this.params, true);
			this.getPermissions({perPage: 1000, action: 'group'}, true);
		}
	};
</script>